import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Tabs } from '@zydalabs/storefront-components';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';

import PickupList from 'modules/productsModule/components/pickupList';
import DeliveryAndAddressList from 'modules/productsModule/components/DeliveryAndAddressList';
import BeachList from 'modules/productsModule/components/BeachList';
import { StyledPageWrapper } from 'common/styles';
import { useGlobalDefaultOrderMode } from 'common/hooks';
import { changeRoute, customHistory, replaceRoute, SessionStorage } from 'common/utils';
import { FULFILLMENT_MODES, URLS } from '../../../../common/constants';
import { useLayout } from '../../../../service/hooks/layout';
import { StyledModesTabWrapper } from './style';

const StoreFrontHeaderComponent = dynamic(() => import('common/components/StoreFrontHeader'));

const OrderModePage = () => {
  const router = useRouter();
  const { t } = useTranslation('layout');
  const { data } = useLayout();
  const { enabledOrderModes } = data || {};
  const [globalDefaultOrderMode] = useGlobalDefaultOrderMode();

  const { mode } = router?.query;
  const [availableModes, setAvailableModes] = useState([]);
  const [currentMode, setCurrentMode] = useState(mode);

  useEffect(() => {
    if (!mode && globalDefaultOrderMode) {
      changeRoute({
        pathname: URLS.ORDER_MODE,
        query: { ...router?.query, mode: globalDefaultOrderMode },
      });
    }
  }, [mode, globalDefaultOrderMode]);

  useEffect(() => {
    if (!data) return;
    const modes = [];
    const modesComponents = {
      [FULFILLMENT_MODES.DELIVERY]: <DeliveryAndAddressList />,
      [FULFILLMENT_MODES.PICKUP]: <PickupList mode={FULFILLMENT_MODES.PICKUP} />,
      [FULFILLMENT_MODES.CAR_PICKUP]: <PickupList mode={FULFILLMENT_MODES.CAR_PICKUP} />,
      [FULFILLMENT_MODES.BEACH]: <BeachList mode={FULFILLMENT_MODES.BEACH} />,
    };

    Object.keys(enabledOrderModes).forEach(key => {
      if (enabledOrderModes[key])
        modes.push({
          name: t(key),
          tabValue: key,
          component: modesComponents[key],
        });
    });
    setAvailableModes(modes);
  }, [data, t]);

  const handleModeClick = newMode => {
    setCurrentMode(newMode);
    replaceRoute({
      pathname: URLS.ORDER_MODE,
      query: { ...router?.query, mode: newMode },
    });
  };

  const handleOnBack = () => {
    SessionStorage.removeOrderNumber();
    customHistory.back();
  };

  return (
    <StyledPageWrapper>
      <StoreFrontHeaderComponent title={t('orderMode')} onBack={handleOnBack} />
      <StyledModesTabWrapper>
        <Tabs
          tabs={availableModes}
          value={currentMode || globalDefaultOrderMode}
          onChange={newMode => handleModeClick(newMode)}
          variant="underlined"
        />
      </StyledModesTabWrapper>
    </StyledPageWrapper>
  );
};

export default OrderModePage;
